import axios from 'axios';
// import { loginreq, req } from './axiosFun';
import req from '@/utils/axios'
import { getToken } from '@/utils/auth'
// 登录接口
//  export const login = (params) => { return req("post", "/api/login", params) };
 export function login(params) { return req({
    url: '/login',
    method: 'post',
    data:params
  })
}

//验证码
export function getCode(params) { return req({
  url: '/captcha.jpg',
  method: 'get',
  data:params,
  responseType: "blob"
})
}


//登录重写
export function rewriteLogin(params) { return req({
  url: '/login_',
  method: 'post',
  data:params,
})
}
//退出登录
export function rewriteLogout() { return req({
  url: '/logout',
  method: 'get',
})
}
/**
 * 用户管理
 **/
//用户列表
export function userList(params) { return req({
  url: '/app-user/listByPage',
  method: 'post',
  data:params,

})
}

// app 端用户个人信息
export function appUserInfo(params) { return req({
  url: '/app-user/getInfo/'+params,
  method: 'get',
})
}

// 获取个人信息
export function userInfo(params) { return req({
  url: '/user/info',
  method: 'get',
  data:params,

})
}
// 获取当前登录信息
export function getInfo(params) { return req({
  url: '/info',
  method: 'get',
  data:params,

})
}

/**
 * 角色管理
 **/
// 获取角色列表
export function getRole(params) { return req({
  url: '/role',
  method: 'get',
  data:params,

})
}
// /保存角色以及菜单权限
export function saveRole(params) { return req({
  url: '/role',
  method: 'post',
  data:params,

})
}



/**
 * IM管理
 **/
//详情查看
export function reportContent(reportId) { return req({
  url: `/report/content/${reportId}`,
  method: 'get',

})
}
//分页数据
export function reportSelect(params) {

  return req({
  url: `/report/content/page`,
  method: 'post',
  data:params
})
}
// 保存编辑信息
export function reportSave(params) { return req({
  url: `/report`,
  method: 'post',
  data:params
})
}

/**
 * 基础信息审核
 **/
//分页
export function userauditSelect(params) {
  return req({
  url: `/app/user-audit/page`,
  method: 'post',
  data:params
})
}

//详情
export function userAuditDetails(params) { return req({
  url: `/app/user-audit/getUserAuditDetails`,
  method: 'get',
  params,
})
}

//审核
export function userAuditToexamine(params) { return req({
  url: `/app/user-audit/audit`,
  method: 'post',
  data:params,
})
}













