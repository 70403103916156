import axios from 'axios'
import qs from 'qs';
import { Message, MessageBox } from 'element-ui'
import store from '../store'
// 创建axios实例
let service =  axios.create({
  baseURL: '/admin-api', // api的base_url
  //  timeout: 10000, // 请求超时时间
  withCredentials: true,
  headers: {
     'Content-Type': 'application/json;charset=utf-8',
     'Access-Control-Allow-Origin': '*',
     'cache-control': "no-cache",
   }

})
// request拦截器
service.interceptors.request.use(config => {

  // TODO 考虑优化
  let token = sessionStorage.getItem('Token')
  if (token) {
    config.headers['Authorization'] = "Bearer " + token//携带权限参数
     if(config.method === 'delete') {
      // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      }else if(config.method.toLowerCase() == "post"){

        if (config.data instanceof FormData) { //文件类,文件类不需要stringify
          config.headers['Content-Type'] = 'multipart/form-data' //修改`Content-Type`
      }
      }
  }
  return config
}, error => {
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
   /**
    * code:200,接口正常返回;
    */
    const res = response.data
    if (response.status==200) {
      if (response.config.responseType == "blob") {

          return response
        }
      if (res.code == undefined) {

        return response.data
      }

      if (res.code !== 200) {
        Message({
          message: res.msg,
          type: 'error',
          duration: 5 * 1000
        })
        // 根据服务端约定的状态码：5001:非法的token; 5002:其他客户端登录了; 5004:Token 过期了;
        // if (res.code === 401) {
        //     MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
        //       confirmButtonText: '重新登录',
        //       cancelButtonText: '取消',
        //       type: 'warning'
        //     }).then(() => {
        //         store.dispatch('LogOut').then(() => {
        //           location.reload()// 为了重新实例化vue-router对象 避免bug
        //         })
        //     })
        // }
        return Promise.reject('error')
      } else { // res.code === 200,正常返回数据
          return response.data
      }
    }

  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
/**
 * get 请求方法
 * @param url
 * @param params
 * @returns {Promise}
 */
// export function get(url, params = {}) {
//   return new Promise((resolve, reject) => {
//       service
//           .get(url, {
//               params: params
//           })
//           .then(response => {
//               resolve(response)
//           })
//           .catch(err => {
//               reject(err)
//           })
//   })
// }

/**
* post 请求方法
* @param url
* @param data
* @returns {Promise}
*/
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
      service.post(url, qs.stringify(data)).then(
          response => {
              // console.log(response.data.code)
              resolve(response)
          },
          err => {
              reject(err)
          }
      )
  })
}
export default service
