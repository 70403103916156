/**
* 左边菜单
*/
<template>
  <div>

    <div class="logobox">
      <img class="logoimg" src="../assets/img/logo.png" alt="">
    </div>


  <!-- <el-menu :collapse="collapsed" collapse-transition router :default-active="$route.path" unique-opened class="el-menu-vertical-demo" background-color="#293A58" text-color="#fff" active-text-color="#fff"   >
      <el-submenu  v-for="menu in allmenu" :key="menu.menuId+'-only'" :index="menu.menuId+'-only'"  >
        <template slot="title" >
          <i class="iconfont" :class="menu.icon"></i>
          <span>{{menu.name}}</span>
        </template>
        <el-menu-item-group >
          <el-menu-item @click="getChMenu(chmenu.name,chmenu.childrenItem)" v-for="chmenu in menu.children" :index="'/'+chmenu.path" :key="chmenu.menuId">
            <i class="iconfont" :class="chmenu.icon"></i>
            <span >{{chmenu.name}}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      </el-menu> -->
        <el-menu :collapse="collapsed" collapse-transition router :default-active="$route.path" unique-opened class="el-menu-vertical-demo" background-color="#293A58" text-color="#fff" active-text-color="#fff" >
    <template v-for="menu in allmenu">
      <template v-if="menu.children && menu.children.length > 0">
        <el-submenu :index="menu.menuId.toString()">
          <template slot="title">{{ menu.name }}</template>
          <template v-for="subMenu in menu.children">
            <template v-if="subMenu.children && subMenu.children.length > 0">
              <el-submenu :index="subMenu.menuId.toString()">
                <template slot="title">{{ subMenu.name }}</template>
                <el-menu-item-group v-for="(nestedSubMenu,index) in subMenu.children" :key="index+nestedSubMenu.menuId.toString()+'_'">
                  <el-menu-item :index="'/'+nestedSubMenu.path"  @click="getChMenu(nestedSubMenu.childrenItem,menu,subMenu,nestedSubMenu)" >{{ nestedSubMenu.name }}</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </template>
            <template v-else>
              <el-menu-item :index="'/'+subMenu.path"  @click="getChMenu(subMenu.childrenItem,menu,subMenu)" >{{ subMenu.name }}</el-menu-item>
            </template>
          </template>
        </el-submenu>
      </template>
      <template v-else>
        <el-menu-item :index="'/'+menu.path">{{ menu.name }}</el-menu-item>
      </template>
    </template>
  </el-menu>
</div>
</template>
<script>
 import {getBreadcrumb} from '../utils/util'
export default {
  name: 'leftnav',
  data() {
    return {
      collapsed: false,
      allmenu: [  ],
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
  },
  mounted () {
    // this.allmenu =  getBreadcrumb() ;
    // getBreadcrumb((data)=>{
    //   this.allmenu = data
    //   this.$root.Bus.$on('toggle', value => {
    //   this.collapsed = !value
    // })
    // })


   this.getBreadcrumb()
  },
  methods: {
   getBreadcrumb(){
    this.allmenu= JSON.parse(sessionStorage.getItem('allmenu'))
      this.$root.Bus.$on('toggle', value => {
      this.collapsed = !value

    })
  },
    getChMenu(childrenItem,menu,subMenu,nestedSubMenu) {

      this.$store.commit('getChildrenItem',childrenItem)
     sessionStorage.setItem('childrenItem',JSON.stringify(childrenItem))

     let arr=[
       { name:menu.name,path:menu.path,menuId:menu.menuId},
       { name:subMenu.name,path:subMenu.path,menuId:subMenu.menuId},
       { name:nestedSubMenu?nestedSubMenu.name:undefined,path:nestedSubMenu?nestedSubMenu.path:undefined,menuId:nestedSubMenu?nestedSubMenu.menuId:undefined},
     ].filter((item)=>{
        return item.name
     })
     console.log(arr,'arr');
      this.$root.Bus.$emit('ChMenu', arr)
    },
  },
  beforeDestroy () {
    this.$bus.$off('ChMenu');
  },
}
</script>
<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  min-height: 400px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}
/deep/.el-menu-item-group__title {
   padding: 3px 45px !important;
}
.el-menu-bg {
  /* background-color: #293A58 !important; */
}
.el-menu {
  border: none;
}
.logobox {
  height: 40px;
  line-height: 44px;
  /* color: #9d9d9d; */
  font-size: 14px;
  text-align: center;
  padding: 20px 0px;
}
.logoimg {
  height: 40px;
}
.el-menu-item.is-active {
    background-color:  #186DF5 !important;
    margin: 0 16px;
    border-radius: 8px;

  }
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: auto;
    /* min-height: 400px; */
  }
  .el-submenu .el-menu-item {
    height: 50px;
    line-height: 50px;
     padding: 0 45px;
    min-width: auto;
    margin: 0 16px;
    border-radius: 8px;
}
.iconfont{
  margin-right: 6px;
}
</style>
