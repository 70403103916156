<template>
  <!-- <el-container class="index-con">
    <el-header class="index-header">
      <navcon></navcon>
    </el-header>
    <el-container class="index-con">
      <el-aside :class="showclass">
        <leftnav></leftnav>
      </el-aside>
      <el-container class="main-con">
        <el-main clss="index-main">
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </el-container> -->

  <el-container class="index-con">
    <el-aside :class="showclass">
      <leftnav></leftnav>
    </el-aside>
    <el-container class="main-con">
      <el-header class="index-header" style="height: 48px;">
        <navcon></navcon>
      </el-header>
      <!-- 面包屑导航 -->
      <el-container class="bread-crumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }" @click.native="gethome">首页</el-breadcrumb-item>
            <!-- <el-breadcrumb-item  :to="{ path: item.path&&item.path!=''? ('/'+item.path):undefined }"  v-for="item in chmenuList" :key="item.menuId+'-only'" >{{ item.name }}</el-breadcrumb-item> -->

                <!-- <el-breadcrumb class="app-breadcrumb" separator="/"> -->
                    <transition-group name="breadcrumb" class="app-breadcrumb">
                      <el-breadcrumb-item v-for="(item,index) in chmenuList" :key="item.menuId+index+'-only'">
                        <a class="a-breadcrumb" v-if="item.path&&item.path!=''" @click.prevent="handleLink(item)">{{ item.name}}</a>
                        <span v-else >{{ item.name }}</span>
                      </el-breadcrumb-item>
                    </transition-group>
                </el-breadcrumb>
          <!-- </el-breadcrumb> -->
        </el-container>
      <el-main clss="index-main" style="background-color: #F3F4F7;padding: 24px;">
        <div class="content">
          <router-view :childrenItem="childrenItem"></router-view>
        </div>

      </el-main>
    </el-container>
  </el-container>
</template>
<script>
// 导入组件
import navcon from '../components/navcon.vue'
import leftnav from '../components/leftnav.vue'
export default {
  name: 'index',
  data() {
    return {
      showclass: 'asideshow',
      showtype: false,
      chmenuList:[],
      childrenItem:[]
    }
  },
  // 注册组件
  components: {
    navcon,
    leftnav
  },
  methods: {
    gethome(){
      this.chmenu=''
    },
    // btPermissions(childrenItem){
    //   childrenItem.forEach(item => {
    //      debugger
    //   });
    // }
    handleLink(item) {
  const { name, path } = item
  if (path) {
    this.$router.push('/'+path)
    return
  }
}

  },
  created() {
    // 监听
    this.$root.Bus.$on('toggle', value => {
      if (value) {
        this.showclass = 'asideshow'
      } else {
        setTimeout(() => {
          this.showclass = 'aside'
        }, 300)
      }
    })
    this.$root.Bus.$off("ChMenu");
    this.$root.Bus.$on('ChMenu', value => {
      console.log(value,'ChMenu');
        this.chmenuList=value
        sessionStorage.setItem('chmenuList', JSON.stringify(value));
    })
    const storedChmenuList = JSON.parse(sessionStorage.getItem('chmenuList'));
      if (storedChmenuList) {
        this.chmenuList = storedChmenuList;
      }
  },
  beforeUpdate() {},
  // 挂载前状态(里面是操作)
  beforeMount() {
    // 弹出登录成功

  }
}
</script>
<style >

/*滚动条的宽度和高度*/
::-webkit-scrollbar {
	width: 6px;
	height: 5px;

}

::-webkit-scrollbar-track {
	width: 5px;
	/* background-color: rgba(238, 238, 238, 0.25); */
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}
/*滚动条的设置*/
::-webkit-scrollbar-thumb {
	background-color: rgba(193, 193, 193, 0.25);
	background-clip: padding-box;
	min-height: 28px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}
/*滚动条移上去显示的的颜色*/
::-webkit-scrollbar-thumb:hover {
	/* background-color: red; */
}


.index-con {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
}
.main-con{
  flex: 1;
}
.aside {
  width: 64px !important;
  height: 100%;
  background-color: #293A58;
  margin: 0px;
}
.asideshow {
  width: 247px !important;
  height: 100%;
  background-color: #293A58;
  margin: 0px;

}
.asideshow>div{
  width: 232px !important;
}
.index-header,
.index-main {
  padding: 0px;
  border-left: 2px solid #333;
  height: 48px;
}
.index-main{
  background-color: green !important;
}
.bread-crumb{
  height: 40px;
  padding: 10px;
  flex: none;
}
.content{
  /* background-color: white; */
  height: calc(100% );
  /* padding: 20px; */
}
.a-breadcrumb:hover{
  cursor: pointer !important;
  color: #409EFF !important;
}
.a-breadcrumb{
  color: #303133;
  font-size: 700 !important;
}
</style>
