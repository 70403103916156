<template>
  <div class="login-wrap">
    <div class="login-container">
    <el-form  label-position="left" :model="ruleForm" :rules="rules" ref="ruleForm"  class="demo-ruleForm ">
      <h3 class="title">极元意境运营管理后台</h3>
      <el-form-item prop="username" label="账号">
        <el-input type="text" v-model="ruleForm.username" auto-complete="off" placeholder="请输入账号"></el-input>
      </el-form-item>
      <el-form-item prop="password" label="密码">
        <el-input type="password" v-model="ruleForm.password" auto-complete="off" placeholder="请输入密码"></el-input>
      </el-form-item>
      <!-- <el-row class="bg-code">
        <el-col :span="12">
          <el-form-item prop="captcha">
            <el-input type="text" v-model="ruleForm.captcha" auto-complete="off" placeholder="图形验证码" @keyup.enter.native="submitForm('ruleForm')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="code-box">
          <img :src="codeimg" alt="" class="codeimg" @click="getcode()">
        </el-col>
      </el-row> -->
      <el-checkbox class="remember" v-model="rememberpwd">记住密码</el-checkbox>
      <el-form-item style="width:100%;">
        <!-- :loading="logining" -->
        <el-button type="primary" style="width:100%;" @click="submitForm('ruleForm')" class="login-bt" :loading="loginLoading">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
  </div>
</template>
<script type="text/ecmascript-6">
import { login,getCode ,rewriteLogin } from '../api/userMG'
import { setCookie, getCookie, delCookie } from '../utils/util'
import { setToken } from '../utils/auth'
import axios  from 'axios'
import store from '../store'
// import jwt from 'jsonwebtoken'
export default {
  name: 'login',
  data() {
    return {
      //定义loading默认为false
      logining: false,
      // 记住密码
      rememberpwd: false,
      ruleForm: {
        //username和password默认为空
        username: '',
        password: '',
        captcha:''
      },
      codeimg:"",
      loginLoading:false,
      //rules前端验证
      rules: {
        username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      }
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
    // this.$message({
    //   message: '账号密码及验证码不为空即可',
    //   type: 'success'
    // })
    // 获取图形验证码
    // this.getcode()
    // 获取存在本地的用户名密码
    this.getuserpwd()

  },
  // 里面的函数只有调用才会执行
  methods: {
    // 获取用户名密码
    getuserpwd() {
      if (getCookie('user') != '' && getCookie('pwd') != '') {
        this.ruleForm.username = getCookie('user')
        this.ruleForm.password = getCookie('pwd')
        this.rememberpwd = true
      }
    },
    // getcode(){
    //   getCode().then(res => {

    //     const blob = new Blob([res], {
    //     // 如何后端没返回下载文件类型，则需要手动设置：type: 'application/pdf;chartset=UTF-8' 表示下载文档为pdf，如果是word则设置为		  msword，excel为excel
    //     type: "image/jpg"
    //   });
    //       this.codeimg=window.URL.createObjectURL(blob)
    //        console.log(this.codeimg,'url');

    //       }).catch(err =>{

    //       })
    // },
    //获取info列表

    submitForm(formName){
      this.loginLoading=true
      setToken("Token",'')
      sessionStorage.setItem("Token",'')
      this.$refs[formName].validate(valid => {
         if (valid) {
          let userInfo={
            username: this.ruleForm.username,
            password: this.ruleForm.password,
          }
          // 注释
          rewriteLogin(userInfo).then(res => {
            if (res.code==200) {
              this.loginLoading=false
              setToken("Token",res.data)
              sessionStorage.setItem("Token",res.data.token )
              sessionStorage.setItem("allmenu",JSON.stringify([...res.data.menus])  )
              this.$store.commit('login', 'true')
							this.$router.push({ path: '/home' })
              // this.$nextTick(res=>{
              //   window.location.reload();
              // })


            }
          }).catch(err =>{
            this.loginLoading=false
            this.$router.push({ path: '/login' })
          })
        } else {
          this.loginLoading=false
          this.$message.error('请输入用户名密码！')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.login-wrap {
  box-sizing: border-box;
  width: 100%;
  height: calc(100%);
  padding-top: 10%;
  background-image: url('../assets/img/login.png');
  /* background-color: #112346; */
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 100%;
  display: table;
  background-size: cover;
}
.login-container {
  margin: 0px auto;
  width: 490px;
  .demo-ruleForm{
    border-radius: 10px;
    padding: 30px 78px 15px 76px;
    border: 1px solid #eaeaea;
    text-align: left;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
    vertical-align: middle;
  }
}
.title {
  margin-top: 50px;
  font-size: 24px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #186DF5;
line-height: 34px;
text-align: center;
margin-bottom: 26px;
}
.login-bt{
  background: #186DF5;
  margin-bottom: 132px;
}
.remember {
  margin: 0px 0px 35px 0px;
  // margin-left: 60px;
}
.code-box {
  text-align: right;
  /* width: 80px;
  height: 30px;
  border: 1px solid; */
  margin-top: 10px;
}
.bg-code{
  display: flex;
  justify-content: space-between;

}
.codeimg {
  height: 40px;
}
</style>
