import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
// 登录验证
export default new Vuex.Store({
    state: {
        user: false,
        childrenItem:[],
        storeNamePub:{}
    },

    mutations: {
        // 登录
        login(state, user) {
            state.user = user;
            localStorage.setItem("userInfo", user);
        },
        // 退出
        logout(state, user) {
            state.user = "";
            localStorage.setItem("userInfo", "");
        },
       getChildrenItem(state,childrenItem){
         state.childrenItem = childrenItem;
       },
       getstoreNamePub(state,storeName){
        state.storeNamePub = storeName;
        sessionStorage.setItem("storeNamePub", JSON.stringify(storeName) );
      },

    },

})
