// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
// 引入element UI
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
// 使用element UI
Vue.use(ElementUI);
import App from './App';
// 引入路由
import router from './router';
// 引入状态管理
import store from './vuex/store';
// 引入icon
import './assets/icon/iconfont.css'
//
import  "@/mock";
// 引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

import axios from 'axios';
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;
import '@/assets/css/base.css'
import '@/style/common.less'
import permission from'./directive/permission'
Vue.use(permission)
import '@/style/ui-page.less'

// import * as imageConversion from 'image-conversion'
// Vue.prototype.$imageConversion = imageConversion

// 过滤器
import * as custom from './utils/util'

Object.keys(custom).forEach(key => {
    Vue.filter(key, custom[key])
})


// 路由拦截器

router.beforeEach((to, from, next) => {
  window.onresize = function() {
    // console.log('离开了')
  }
  if (to.matched.length != 0) {

      if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
          if (sessionStorage.getItem('Token')) { // 通过vuex state获取当前的user是否存在
              next();
          } else {
              next({
                  path: '/login',
                  query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
              })
          }
      } else {
          if (sessionStorage.getItem('Token')) { // 判断是否登录
              if (to.path != "/" && to.path == "/login") { //判断是否要跳到登录界面
                  next();
              } else {
                  /**
                   * 防刷新，如果登录，修改路由跳转到登录页面，修改路由为登录后的首页
                   */
                    next({
                      path: '/home'
                  })
              }
          } else {
              next();
          }
      }
  } else {
      next({
          path: '/login',
          query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
  }
})

/* eslint-disable no-new */
// new Vue({
//     el: '#app',
//     router,
//     store, //使用store vuex状态管理
//     components: { App },
//     template: '<App/>',
//     data: {
//         // 空的实例放到根组件下，所有的子组件都能调用
//         Bus: new Vue()
//     }

// })
new Vue({
  el:'#app',
  router,
  store, //使用store vuex状态管理
  beforeCreate() {
    Vue.prototype.$bus = this  //全局安装配置全局事件总线
  },
  //  这个函数与我们写的是差不多的
 // 定义并注册了App;使用了App组件;以及比上面多了一个作用，自动寻找解析器的loader
 // 相比于上面那种写法，它的打包体积更小一点
  render:h=>h(App),
  data: {
    // 空的实例放到根组件下，所有的子组件都能调用
    Bus: new Vue()
}
})
