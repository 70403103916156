
/**
 * @param {date} 标准时间格式:Fri Nov 17 2017 09:26:23 GMT+0800 (中国标准时间)
 * @param {type} 类型
 *   type == 1 ---> "yyyy-mm-dd hh:MM:ss.fff"
 *   type == 2 ---> "yyyymmddhhMMss"
 *   type == '' ---> "yyyy-mm-dd hh:MM:ss"
 */
export const formatDate = (date, type) =>{
  var year = date.getFullYear();//年
  var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;//月
  var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();//日
  var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();//时
  var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();//分
  var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();//秒
  var milliseconds = date.getMilliseconds() < 10 ? "0" + date.getMilliseconds() : date.getMilliseconds() //毫秒
  if (type == 1) {
      return year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds + "." + milliseconds;
  } else if(type == 2){
      return year+""+month+""+day+""+hour+""+minutes+""+seconds;
  }else if(type == 3){
      return year + "-" + month + "-" + day;
  }else if(type == 4){
      return year + " 年 " + month + " 月"
  }else{
      return year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds;
  }
}
/**
 * 时间戳
 * @param {*} timestamp  时间戳
 */
const timestampToTime = (timestamp) => {
    let date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '-'
    let M =
        (date.getMonth() + 1 < 10 ?
            '0' + (date.getMonth() + 1) :
            date.getMonth() + 1) + '-'
    let D =
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
    let h =
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    let m =
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
        ':'
    let s =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return Y + M + D + h + m + s
};
/**
 * @description 注册回车事件
 */
export const handleEnter = (sel) => {
  document.onkeydown = (e) => { //按下回车查询
    var event = e || window.event;
    if (event.keyCode && event.keyCode == 13) {
      sel();
    } else if (event.which == 13) {
      sel();
    }
  };
};
/**
 * 列表查询参数处理
 * @param {*}  row 对象
 */

const getObjArr =(row,flag)=>{
     let list = [];
      let info = { };
      for (const key in row) {
        if (row[key]||row[key]==0) {
          switch(key){
            case 'start':
              info = {
                fieldName: 'age',
                type: ">=",
                value:  row[key] ,
              };
                break;
            case 'end':
              info = {
                fieldName: 'age',
                type: "<=",
                value:  row[key],
              };
                break;
            case 'startTime':
              info = {
                fieldName: 'createTime',
                type: ">=",
                value: row[key],
              };
                break;
            case 'endTime':
              info = {
                fieldName: 'createTime',
                type: "<=",
                value: row[key],
              };
                break;
            case 'searchKey':
                  info = {
                    fieldName: 'searchKey',
                    type: "like",
                    value: row[key],
                  };
                    break;
            default:
              if (flag==true) {
                info = {
                  fieldName: key,
                  type: "like",
                  value: row[key],
                };
              }else{
                info = {
                  fieldName: key,
                  type: "=",
                  value: row[key],
                };
              }

                break
        }



        list.push(info)
        }

      }
      return list
}


/**
 * 存储localStorage
 */
const setStore = (name, content) => {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(name, content);
}

/**
 * 获取localStorage
 */
const getStore = name => {
    if (!name) return;
    return window.localStorage.getItem(name);
}

/**
 * 删除localStorage
 */
const removeStore = name => {
    if (!name) return;
    window.localStorage.removeItem(name);
}
/**
 * 文件下载
 */
const excel=(response)=>{
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
       window.navigator.msSaveBlob(new Blob([response], { type: 'application/vnd.ms-excel' }), '表格.xls')
     } else {
       let url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }))
       let link = document.createElement('a')
       link.style.display = 'none'
      link.href = url
        // 取后端给前端返的请求头中的文件名称
          const temp =response.headers["content-disposition"].split(";")[1].split("filename=")[1];
          const fileName = decodeURIComponent(temp)

       link.setAttribute('download',fileName)
       document.body.appendChild(link)
       link.click()
       document.body.removeChild(link); //下载完成移除元素
      window.URL.revokeObjectURL(url); //释放掉blob对象
     }
};


/**
 * 设置cookie
 **/
function setCookie(name, value, day) {
    let date = new Date();
    date.setDate(date.getDate() + day);
    document.cookie = name + '=' + value + ';expires=' + date;
};

/**
 * 获取cookie
 **/
function getCookie(name) {
    let reg = RegExp(name + '=([^;]+)');
    let arr = document.cookie.match(reg);
    if (arr) {
        return arr[1];
    } else {
        return '';
    }
};

/**
 * 删除cookie
 **/
function delCookie(name) {
    setCookie(name, null, -1);
};

/**
 *  获取面包屑导航
 **/
function getBreadcrumb(callback) {
  // 获取图形验证码
   let res = {
    success: true,
    data: [
      {
        menuid: 1,
        icon: 'li-icon-xiangmuguanli',
        menuname: '后台角色权限管理',
        hasThird: null,
        url: null,
        menus: [
          {
            menuid: 2,
            icon: 'icon-cat-skuQuery',
            menuname: '部门管理',
            hasThird: 'N',
            url: 'rolePre/department',
            menus: null
          },{
            menuid: 3,
            icon: 'icon-cat-skuQuery',
            menuname: '员工管理',
            hasThird: 'N',
            url: 'rolePre/staff',
            menus: null
          },{
            menuid: 4,
            icon: 'icon-cat-skuQuery',
            menuname: '角色管理',
            hasThird: 'N',
            url: 'rolePre/roles',
            menus: null
          },
        ]
      },
      {
        menuid: 22,
        icon: 'li-icon-xiangmuguanli',
        menuname: '广告管理',
        hasThird: null,
        url: null,
        menus: [
          {
            menuid: 23,
            icon: 'icon-cat-skuQuery',
            menuname: '广告管理',
            hasThird: 'N',
            url: 'adManagement/manageAds',
            menus: null
          },
        ]
      },
      {
        menuid: 5,
        icon: 'li-icon-xitongguanli',
        menuname: '系统管理',
        hasThird: null,
        url: null,
        menus: [
          {
            menuid: 6,
            icon: 'icon-cus-manage',
            menuname: '用户管理',
            hasThird: 'N',
            url: 'system/user',
            menus: null
          },
          {
            menuid: 7,
            icon: 'icon-cus-manage',
            menuname: '用户标签',
            hasThird: 'N',
            url: 'system/userLable',
            menus: null
          },
          {
            menuid: 19,
            icon: 'icon-cus-manage',
            menuname: '基础信息审核',
            hasThird: 'N',
            url: 'system/auditInfor',
            menus: null
          },

        ]
      },
      {
        menuid: 8,
        icon: 'li-icon-xitongguanli',
        menuname: '元宇宙管理',
        hasThird: null,
        url: null,
        menus: [
          {
            menuid: 9,
            icon: 'icon-cus-manage',
            menuname: '场景管理',
            hasThird: 'N',
            url: 'universe/scene',
            menus: null
          },
        ]
      },
      {
        menuid: 10,
        icon: 'li-icon-xitongguanli',
        menuname: '广场动态管理',
        hasThird: null,
        url: null,
        menus: [
          {
            menuid: 11,
            icon: 'icon-cus-manage',
            menuname: '动态管理',
            hasThird: 'N',
            url: 'square/trends',
            menus: null
          },
          {
            menuid: 12,
            icon: 'icon-cus-manage',
            menuname: '评论管理',
            hasThird: 'N',
            url: 'square/comment',
            menus: null
          },
        ]
      },
      {
        menuid: 13,
        icon: 'li-icon-xitongguanli',
        menuname: '个人信息',
        hasThird: 'N',
        url: 'tcm/perInfo',
        menus: null
      },
      {
        menuid: 14,
        icon: 'li-icon-xitongguanli',
        menuname: '常见问题',
        hasThird: 'N',
        url: 'tcm/comManage',
        menus: null
      },
      {
        menuid: 15,
        icon: 'li-icon-xitongguanli',
        menuname: '医生管理',
        hasThird: 'N',
        url: 'tcm/docManage',
        menus: null
      },
      {
        menuid: 16,
        icon: 'li-icon-xitongguanli',
        menuname: '患者管理',
        hasThird: 'N',
        url: 'tcm/patientMa',
        menus: null
      },
      {
        menuid: 17,
        icon: 'li-icon-xitongguanli',
        menuname: '科室管理',
        hasThird: 'N',
        url: 'tcm/departManage',
        menus: null
      },
      {
        menuid: 18,
        icon: 'li-icon-xitongguanli',
        menuname: '疾病管理',
        hasThird: 'N',
        url: 'tcm/disease',
        menus: null
      },
      {
        menuid: 20,
        icon: 'li-icon-xitongguanli',
        menuname: 'IM管理',
        hasThird: null,
        url: null,
        menus: [
          {
            menuid: 21,
            icon: 'icon-cus-manage',
            menuname: '举报管理',
            hasThird: 'N',
            url: 'configureIM/report',
            menus: null
          },

        ]
      },
    ],
    msg: 'success'
  }
        // this.allmenu = res.data

  // menu(localStorage.getItem('logintoken'))
  //   .then(res => {
  //     console.log(JSON.stringify(res))
  //     if (res.success) {
  //       this.allmenu = res.data
  //     } else {
  //       this.$message.error(res.msg)
  //       return false
  //     }
  //   })
  //   .catch(err => {
  //     this.$message.error('菜单加载失败，请稍后再试！')
  //   })
  // 监听


  return callback( res.data)
};
function getMinutes(obs){
  let  time = new Date().getTime() -obs.expires_at;
  let leave1=time%(24*3600*1000)
  let leave2=leave1%(3600*1000)
  let  minutes=Math.floor(leave2/(60*1000))
  return -1*minutes
};
//过滤器
export const privateMob= function(str) {
  var str = str=str.replace(/\s+/g,"");
  if (str === "" || str == null) {
    return (str = "");
  } else if(str.length==11){
    return str.substr(0, 3) + "****" + str.substr(7);
  }else{
    return str.substr(0, 3) + "***********" + str.substr(12);
  }
}
export const  tableHeaderColor=({ row, column, rowIndex, columnIndex })=> {
  return "background-color: #F2F3F5;";
}
/**
 * 时间戳
 * @param {targetValue}   筛选的值
 * @param {arr} 需要筛选数组
 * @param {val} 数组里的字段名
 */
export const  deepSearch=(targetValue, arr,val)=> {
  let result = []; // 存放结果的数组
  function searchHelper(arr) {
      for (let i = 0; i < arr.length; i++) {
          for(let a in arr[i]){
            if (Array.isArray(arr[i][a])) {
                searchHelper(arr[i][a]); // 如果当前元素是数组，则递归
            }else if(a===val) {
              if (arr[i][a] == targetValue) {
                result.push(arr[i])                       // 将符合条件的对象添加到结果数组中
              }
            }
            }
          }
      }
  searchHelper(arr);
  return result;
}
export const  generateCalendar=(startDateStr, endDateStr)=> {
  var startDate = new Date(startDateStr); // 将字符串转换为日期格式
  var endDate = new Date(endDateStr);

  if (startDate > endDate) {
      console.log("开始日期不能大于结束日期");
      return;
  }

  var calendarData = [];
  while (startDate <= endDate) {
      var dateObj = {};

      dateObj["date"] =formatDate(startDate,3) // 获取当前日期并按本地化格式显示
      dateObj["dayOfWeek"] = startDate.getDay(); // 根据星期索引获取星期名称（0-6）

      calendarData.push(dateObj);

      startDate.setDate(startDate.getDate() + 1); // 递增日期
  }

  return calendarData;
}
export const limitImgSize =(val,w=32,h=32)=>{
  const match = val.match(/[?&]w=(\d+)&h=(\d+)/);
    if (!match) {
       return false
    }
    if (match) {
          const originalWidth = parseInt(match[1], 10);
          const originalHeight = parseInt(match[2], 10);

          if (originalWidth==w&&originalHeight==h) {
            return true
          }
    }
    return false
}
/**
 * 导出
 **/
export {
    timestampToTime,
    getObjArr,
    setStore,
    getStore,
    removeStore,
    excel,
    setCookie,
    getCookie,
    delCookie,
    getBreadcrumb,
    getMinutes,
}
