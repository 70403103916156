// 导入组件
import Vue from 'vue';
import Router from 'vue-router';
// 登录
import login from '@/views/login';
// 首页
import index from '@/views/index';


// 权限管理


// 启用路由
Vue.use(Router);

// 导出路由
export default new Router({
  routes: [{
    path: '/',
    name: '',
    component: login,
    hidden: true,
    meta: {
      requireAuth: false
    }
  }, {
    path: '/login',
    name: '登录',
    component: login,
    hidden: true,
    meta: {
      requireAuth: false
    }
  }, {
    path: '/index',
    name: '首页',
    component: index,
    iconCls: 'el-icon-tickets',
    children: [
      {
        path: '/home',
        name: '首页',
        component: () => import('@/views/home/index.vue'),
        meta: {
          requireAuth: true
        }
      }, {
        path: '/system/user',
        name: '用户管理',
        component: () => import('@/views/system/user/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/system/auditInfor',
        name: '基础信息审核',
        component: () => import('@/views/system/auditInfor/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/system/userEdit',
        name: '用户详情',
        component: () => import('@/views/system/user/edit'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/system/userLable',
        name: '用户标签',
        component: () => import('@/views/system/userLable/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/system/userLableEdit',
        name: '编辑标签',
        component: () => import('@/views/system/userLable/edit'),
        meta: {
          requireAuth: true
        }
      }, {
        path: '/rolePre/department',
        name: '部门管理',
        component: () => import('@/views/rolePre/department/index.vue'),
        meta: {
          requireAuth: true
        }
      }, {
        path: '/rolePre/staff',
        name: '员工管理',
        component: () => import('@/views/rolePre/staff/index.vue'),
        meta: {
          requireAuth: true
        }
      }, {
        path: '/rolePre/roles',
        name: '角色管理',
        component: () => import('@/views/rolePre/roles/index.vue'),
        meta: {
          requireAuth: true
        }
      }, {
        path: '/universe/scene',
        name: '场景管理',
        component: () => import('@/views/universe/scene/index.vue'),
        meta: {
          requireAuth: true
        }
      }, {
        path: '/square/trends',
        name: '动态管理',
        component: () => import('@/views/square/trends/index.vue'),
        meta: {
          requireAuth: true
        }
      }, {
        path: '/square/comment',
        name: '评论管理',
        component: () => import('@/views/square/comment/index.vue'),
        meta: {
          requireAuth: true
        }
      }, {
        path: '/tcm/perInfo',
        name: '个人信息',
        component: () => import('@/views/doctor/perInfo/index.vue'),
        meta: {
          requireAuth: true
        }
      }, {
        path: '/tcm/comManage',
        name: '常见问题',
        component: () => import('@/views/doctor/comManage/index.vue'),
        meta: {
          requireAuth: true
        }
      }, {
        path: '/tcm/docManage',
        name: '医生管理',
        component: () => import('@/views/doctor/docManage/index.vue'),
        meta: {
          requireAuth: true
        }
      }, {
        path: '/tcm/patientMa',
        name: '医生管理',
        component: () => import('@/views/doctor/patientMa/index.vue'),
        meta: {
          requireAuth: true
        }
      }, {
        path: '/tcm/departManage',
        name: '科室管理',
        component: () => import('@/views/doctor/departManage/index.vue'),
        meta: {
          requireAuth: true
        }
      }, {
        path: '/tcm/disease',
        name: '科室管理',
        component: () => import('@/views/doctor/disease/index.vue'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/configureIM/report',
        name: '举报管理',
        component: () => import('@/views/configureIM/report/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/adManagement/manageAds',
        name: '广告管理',
        component: () => import('@/views/adManagement/manageAds/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/infoCenter/noticeManage',
        name: '消息中心',
        component: () => import('@/views/infoCenter/noticeManage/index'),
        meta: {
          requireAuth: true
        }
      },
      /**
       * 文旅
       */
      {
        path: '/tourismCulture/home',
        name: '首页',
        component: () => import('@/views/tourismCulture/home/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/goodsCenter/classify',
        name: '分类管理',
        component: () => import('@/views/tourismCulture/goodsCenter/classify/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/goodsCenter/attribute',
        name: '属性管理',
        component: () => import('@/views/tourismCulture/goodsCenter/attribute/index'),
        meta: {
          requireAuth: true
        }
      },

      {
        path: '/goodsCenter/tagManage',
        name: '标签管理',
        component: () => import('@/views/tourismCulture/goodsCenter/tagManage/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/goodsCenter/audit',
        name: '待审核管理',
        component: () => import('@/views/tourismCulture/goodsCenter/audit/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/goodsCenter/commodity',
        name: '商品管理',
        component: () => import('@/views/tourismCulture/goodsCenter/commodity/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/commodity/publishPro',
        name: '发布商品',
        component: () => import('@/views/tourismCulture/goodsCenter/commodity/publishPro/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/goodsCenter/freight',
        name: '运费模板',
        component: () => import('@/views/tourismCulture/goodsCenter/freight/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/freight/orderAdd',
        name: '订单运费模板',
        component: () => import('@/views/tourismCulture/goodsCenter/freight/orderAdd'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/freight/singleAdd',
        name: '单品运费模板',
        component: () => import('@/views/tourismCulture/goodsCenter/freight/singleAdd'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/orderCenter/evaluationManage',
        name: '评价管理',
        component: () => import('@/views/tourismCulture/orderCenter/evaluationManage'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/evaluationManage/evaluateDetails',
        name: '评价详情',
        component: () => import('@/views/tourismCulture/orderCenter/evaluationManage/evaluateDetails'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/orderCenter/orderManage',
        name: '订单管理',
        component: () => import('@/views/tourismCulture/orderCenter/orderManage/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/orderManage/tabDetails',
        name: '客户单详情',
        component: () => import('@/views/tourismCulture/orderCenter/orderManage/tabDetails'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/orderManage/orderEdit',
        name: '订单详情',
        component: () => import('@/views/tourismCulture/orderCenter/orderManage/orderEdit/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/orderCenter/orderShipp',
        name: '发货单管理',
        component: () => import('@/views/tourismCulture/orderCenter/orderShipp/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/orderShipp/editShop',
        name: '发货单详情',
        component: () => import('@/views/tourismCulture/orderCenter/orderShipp/editShop'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/orderCenter/teamWork',
        name: '拼团管理',
        component: () => import('@/views/tourismCulture/orderCenter/teamWork/index'),
        meta: {
          requireAuth: true
        }
      },

      {
        path: '/marketDepart/novaVolume',
        name: '优惠券管理',
        component: () => import('@/views/tourismCulture/marketDepart/novaVolume'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/marketDepart/evenMange',
        name: '活动管理',
        component: () => import('@/views/tourismCulture/marketDepart/evenMange/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/marketDepart/columnManage',
        name: '专栏管理',
        component: () => import('@/views/tourismCulture/marketDepart/columnManage/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/marketDepart/editColumnManage',
        name: '专栏新增/修改',
        component: () => import('@/views/tourismCulture/marketDepart/columnManage/editColumnManage'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/marketDepart/addEvenMange',
        name: '新增活动管理',
        component: () => import('@/views/tourismCulture/marketDepart/evenMange/addEvenMange'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/marketDepart/editNovavolime',
        name: '优惠券详情',
        component: () => import('@/views/tourismCulture/marketDepart/novaVolume/editNovavolime'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/afterSales/barter',
        name: '换货申请',
        component: () => import('@/views/tourismCulture/afterSales/barter'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/barter/editBarter',
        name: '退货退款申请',
        component: () => import('@/views/tourismCulture/afterSales/barter/editBarter'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/afterSales/refund',
        name: '仅退款申请',
        component: () => import('@/views/tourismCulture/afterSales/refund'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/refund/editRefund',
        name: '退货退款申请',
        component: () => import('@/views/tourismCulture/afterSales/refund/editRefund'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/afterSales/refundsRe',
        name: '退货退款申请',
        component: () => import('@/views/tourismCulture/afterSales/refundsRe'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/bookings/orderManage',
        name: '订单管理',
        component: () => import('@/views/bookings/orderManage'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/bookings/orderMagEdit',
        name: '订单详情',
        component: () => import('@/views/bookings/orderManage/orderMagEdit'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/bookings/ticketManage',
        name: '票务管理',
        component: () => import('@/views/bookings/ticketManage'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/bookings/ticketEdit',
        name: '创建票品',
        component: () => import('@/views/bookings/ticketManage/ticketEdit'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/bookings/scenicData',
        name: '景区基础数据',
        component: () => import('@/views/bookings/scenicData'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/bookings/scenicEdit',
        name: '添加景区',
        component: () => import('@/views/bookings/scenicData/scenicEdit'),
        meta: {
          requireAuth: true
        }
      },
      /**
       * 酒店预定系统
       */
      {
        path: '/hotel/basicSet',
        name: '酒店基础设置',
        component: () => import('@/views/hotel/basicSet/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/hotel/home',
        name: '首页',
        component: () => import('@/views/hotel/home/index'),
        meta: {
          requireAuth: true
        }
      },

      {
        path: '/basicSet/calendar',
        name: '价格日历',
        component: () => import('@/views/hotel/basicSet/calendar/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/basicSet/addStore',
        name: '添加门店',
        component: () => import('@/views/hotel/basicSet/addStore/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/basicSet/house',
        name: '房型',
        component: () => import('@/views/hotel/basicSet/house/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/model/medelEdit',
        name: '房型详情',
        component: () => import('@/views/hotel/basicSet/house/model/medelEdit'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/house/room',
        name: '房间',
        component: () => import('@/views/hotel/basicSet/house/room/index'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/room/roomEdit',
        name: '房间详情',
        component: () => import('@/views/hotel/basicSet/house/room/roomEdit'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/orderForm/orderEdit',
        name: '订单详情',
        component: () => import('@/views/hotel/basicSet/house/orderForm/orderEdit'),
        meta: {
          requireAuth: true
        }
      },
      {
        path: '/basicSet/examineList',
        name: '订单详情',
        component: () => import('@/views/hotel/basicSet/examineList/index'),
        meta: {
          requireAuth: true
        }
      },
    ]
  }]
})
