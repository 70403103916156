import Mock from 'mockjs'


let List = []
const count = 1000
let typelist = ['联通', '移动', '电信', '铁通']

for (let i = 0; i < count; i++) {
  List.push(Mock.mock({
    id: Mock.Random.guid(),
    sortnum: i + 1,
    username: Mock.Random.cname(),
    address: Mock.mock('@county(true)'),
    createTime: Mock.Random.datetime(),
    updateTime: Mock.Random.now(),
    ip:Mock.mock('@ip'),
    region:Mock.mock('@region'),
    areaId:/\d{7}/,
    email: Mock.Random.email(),
    'isp|1': typelist
  }))
}

export default {
  // 用户登录
  login: config => {
    let data = JSON.parse(config.body);
    let userList = {};
    if(data.username === 'admin'){
        userList = {
            token:'admin',
            name:'管理员',
        }
    }else if(data.username === 'editor'){
        userList = {
            token:'editor',
            name:'赵晓编',
        }
    }else{
       return {
        code: -1,
        data: {
          msg: "用户名错误",
          status:'fail'
        }
       }
    }
    return {
      code: 200,
      data: {
        userList: userList
      }
    }
  },
   // 用户登出
   logout: config => {
    return {
      code: 200,
      data: {
        userList: ""
      }
    }
  },
  // 获取登录用户信息
  getUserInfo:config => {
    let data = JSON.parse(config.body);
    let userList = {};
    if(data.token === 'admin'){
        userList = {
            roles: ['admin'],
            name:'admin',
            avatar:'https://wx.qlogo.cn/mmopen/vi_32/un2HbJJc6eiaviaibvMgiasFNlVDlNOb9E6WCpCrsO4wMMhHIbsvTkAbIehLwROVFlu8dLMcg00t3ZtOcgCCdcxlZA/132'
        }
    }else if(data.token === 'editor'){
        userList = {
            roles: ['editor'],
            name:'editor',
            avatar:'https://mirror-gold-cdn.xitu.io/168e088859e325b9d85?imageView2/1/w/100/h/100/q/85/format/webp/interlace/1'
        }
    }else{
        userList = {}
    }
    return {
      code: 200,
      data: {
        userList: userList
      }
    }
  },
   /**
   * 获取用户列表
   * 要带参数 name, page, limt; name可以不填, page,limit有默认值。
   * @param name, page, limit
   * @return {{code: number, count: number, data: *[]}}
   */
  getUserList:config => {
    const { limit , page } = JSON.parse(config.body);
    let mockList = List;
    const userList = mockList.filter((item, index) => index < limit * page && index >= limit * (page - 1))
    return {
      code: 200,
      data: {
        total: mockList.length,
        userList: userList
      }
    }
  },

  menu:config=>{
    const List = {
      success: true,
      data: [
        {
          menuid: 1,
          icon: 'icon-cat-skuQuery',
          menuname: '个人信息',
          hasThird: 'N',
          url: '/perInfo',
          menus: null
        },
        {
          menuid: 2,
          icon: 'icon-cat-skuQuery',
          menuname: '常见问题',
          hasThird: 'N',
          url: '/comManage',
          menus: null
        },
        {
          menuid: 3,
          icon: 'icon-cat-skuQuery',
          menuname: '医生管理',
          hasThird: 'N',
          url: '/docManage',
          menus: null
        },
        {
          menuid: 4,
          icon: 'icon-cat-skuQuery',
          menuname: '患者管理',
          hasThird: 'N',
          url: '/patientMa',
          menus: null
        },
        {
          menuid: 5,
          icon: 'icon-cat-skuQuery',
          menuname: '科室管理',
          hasThird: 'N',
          url: '/departManage',
          menus: null
        },
        {
          menuid: 6,
          icon: 'icon-cat-skuQuery',
          menuname: '疾病管理',
          hasThird: 'N',
          url: '/disease',
          menus: null
        },
        {
          menuid: 7,
          icon: 'li-icon-xiangmuguanli',
          menuname: '后台角色权限管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 8,
              icon: 'icon-cat-skuQuery',
              menuname: '部门管理',
              hasThird: 'N',
              url: 'rolePre/department',
              menus: null
            },
            {
              menuid: 9,
              icon: 'icon-cat-skuQuery',
              menuname: '员工管理',
              hasThird: 'N',
              url: 'rolePre/staff',
              menus: null
            },
            {
              menuid: 10,
              icon: 'icon-cat-skuQuery',
              menuname: '角色管理',
              hasThird: 'N',
              url: 'rolePre/roles',
              menus: null
            },
          ]
        },
        {
          menuid: 11,
          icon: 'li-icon-dingdanguanli',
          menuname: '元宇宙管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 12,
              icon: 'icon-order-manage',
              menuname: '场景管理',
              hasThird: 'N',
              url: 'universe/scene',
              menus: null
            }
          ]
        },
        {
          menuid: 13,
          icon: 'li-icon-dingdanguanli',
          menuname: '广场动态管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 14,
              icon: 'icon-order-manage',
              menuname: '动态管理',
              hasThird: 'N',
              url: 'square/trends',
              menus: null
            },
            {
              menuid: 15,
              icon: 'icon-order-manage',
              menuname: '评论管理',
              hasThird: 'N',
              url: 'square/comment',
              menus: null
            }
          ]
        },
        {
          menuid: 16,
          icon: 'li-icon-xitongguanli',
          menuname: '系统管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 17,
              icon: 'icon-cus-manage',
              menuname: '用户管理',
              hasThird: 'N',
              url: 'system/user',
              menus: null
            },
            {
              menuid: 18,
              icon: 'icon-cus-manage',
              menuname: '用户标签',
              hasThird: 'N',
              url: 'system/userLable',
              menus: null
            },
            {
              menuid: 19,
              icon: 'icon-news-manage',
              menuname: '角色管理',
              hasThird: 'N',
              url: 'system/Role',
              menus: null
            },

          ]
        },



      ],
      msg: 'success'
    }
    return {
      code: 200,
      data: {
        List: List
      }
    }
  }


}
