// 在mock下index.js文件中
 import Mock from 'mockjs'
 import userAPI from './user'
// url为接口地址  video为模拟数据
//  Mock.mock(url, 'post', videos )/api/login
// Mock.mock(/\/user\/login/, 'get', userAPI.login)
//  Mock.mock('/Service/ServiceList','post',videos)
//  Mock.mock('/mainairconsystem-service/precise/findWaterLogs','post',videos)
console.log('%c前端 mock 环境启动成功', 'color: #38f;font-weight: bold')

export default Mock
