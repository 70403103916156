/**
* 头部菜单
*/
<template>
  <el-menu class="el-menu-demo" mode="horizontal" background-color="#293A58" text-color="#fff" active-text-color="#fff">
    <el-button class="buttonimg">
      <img class="showimg" :src="collapsed?imgsq:imgshow" @click="toggle(collapsed)">
    </el-button>
    <el-submenu index="2" class="submenu" style="height:48px">
      <template slot="title" style="height:48px">{{userInfo.name}}</template>
      <el-menu-item index="2-1">设置</el-menu-item>
      <el-menu-item @click="content()" index="2-2">个人中心</el-menu-item>
      <el-menu-item @click="exit()" index="2-3">退出</el-menu-item>
    </el-submenu>
  </el-menu>
</template>
<script>
import { loginout,userInfo ,getInfo,rewriteLogout} from '../api/userMG'
import { setToken } from '../utils/auth'
import Base64 from 'jwt-decode'
export default {
  name: 'navcon',
  data() {
    return {
      collapsed: true,
      imgshow: require('../assets/img/show.png'),
      imgsq: require('../assets/img/sq.png'),
      user: {},
      userInfo:{}
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
    this.getLoginInfo()
    this.getUserInfo()
  },
  mounted () {
   this.$nextTick(
      // this.nim = this.NIM.getInstance({
      // // 初始化SDK
      // //   debug: true,
      //   appKey: '你自己的key', // appkey
      //   account: 'admin1', // 当前登录账号（需要自行去后台管理添加如下图，也可以调用接口注册）
      //   token: '123456', // 当前登录密码（这是静态token，动态的即自动登录，可以自行查看官网文档）
      //   onconnect: this.onConnect, // 若登录成功，可通过该回调获取登录信息
      //   onerror: this.onError, // 出现错误的回调，这里的“错误”通常为数据库错误，也可能是连接错误
      //   onwillreconnect: this.onWillReconnect, // NIM SDK 与云信服务端的长连接已断开，而且正在重连时触发的回调函数
      //   ondisconnect: this.onDisconnect, // NIM SDK 与云信服务端的长连接断开后的回调函数
      //   // 会话
      //   onsessions: this.onSessions, // 初始化同步时，用于获取会话列表的回调函数
      //   autoMarkRead: true, // 开启自动标记消息已读
      //   syncSessionUnread: true, // 同步会话的未读数,
      //   onupdatesession: this.onUpdateSession, // 批量更新会话的回调函数。触发条件包括收发消息、清理会话未读数、撤回消息等
      //   // 消息
      //   onroamingmsgs: this.onRoamingMsgs, // 初始化同步时，用于接收漫游消息的回调函数
      //   onofflinemsgs: this.onOfflineMsgs, // 初始化同步时，用于接收离线消息的回调函数
      //   onmsg: this.onMsg, // 用户在线或多端同步时，用于接收消息的回调函数（实时消息）
      //   // 同步完成
      //   onsyncdone: this.onSyncDone // 初始化同步完成的回调函数。推荐在初始化同步完成后再调用 SDK 实例的接口
      // })
    // console.log(this.nim)
    )


 this.$nextTick(()=>{
  this.userInfo=Base64(sessionStorage.getItem('Token'))
 })
  },
  methods: {
    getLoginInfo(){
      // userInfo().then(res=>{
      // })
    },
    getUserInfo(){
      // getInfo({})
      // .then(res => {

      //   // console.log(JSON.stringify(res))
      //   if (res.code==200) {
      //     this.allmenu = res.data
      //   } else {
      //     this.$message.error(res.msg)
      //     return false
      //   }
      // })
      // .catch(err => {
      //   this.$message.error(err.msg)
      // })
    },
    // 退出登录
    exit() {
      this.$confirm('退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          setTimeout(() => {
                  this.$store.commit('logout', 'false')
                  this.$router.push({ path: '/login' })
                  localStorage.setItem("userInfo",'');
                  setToken("Token",'')
                  sessionStorage.setItem("Token",'')
                }, 1000)
          // rewriteLogout().then(res=>{

          //        if (res.code==200) {
          //       //如果请求成功就让他2秒跳转路由
          //       setTimeout(() => {
          //         this.$store.commit('logout', 'false')
          //         this.$router.push({ path: '/login' })
          //         localStorage.setItem("userInfo",'');
          //         setToken("Token",'')
          //         sessionStorage.setItem("Token",'')
          //       }, 1000)
          //     } else {
          //       this.$message.error(res.msg)
          //       this.logining = false
          //       return false
          //     }
          //   })
          // loginout()
          //   .then(res => {
          //     if (res.success) {
          //       //如果请求成功就让他2秒跳转路由
          //       setTimeout(() => {
          //         this.$store.commit('logout', 'false')
          //         this.$router.push({ path: '/login' })
          //         this.$message({
          //           type: 'success',
          //           message: '已退出登录!'
          //         })
          //       }, 1000)
          //     } else {
          //       this.$message.error(res.msg)
          //       this.logining = false
          //       return false
          //     }
          //   })
          //   .catch(err => {
          //     // 获取图形验证码
          //     this.getcode()
          //     this.logining = false
          //     this.$message.error('退出失败，请稍后再试！')
          //   })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    // 切换显示
    toggle(showtype) {
      this.collapsed = !showtype
      this.$root.Bus.$emit('toggle', this.collapsed)
    }
  }
}
</script>
<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
}
.submenu {
  float: right;
  height: 48px;

}
.submenu>div {
  height: 48px;

}

.buttonimg {
  height: 48px;

  background-color: transparent;
  border: none;
}
.showimg {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 17px;
  left: 17px;
}
.showimg:active {
  border: none;
}
</style>
<style lang="less" scoped>
/deep/.el-submenu__title{
  height: 48px !important;
}
</style>
