const permission = {
	// 全局注册自定义指令
  install (Vue) {
    // 使用 inserted 函数，在被绑定元素插入父节点时检测该元素是否有权限
    Vue.directive('permission', {
      inserted (el, binding, vnode) {
        // console.log(el,'el');
        // console.log(binding,'binding');
		 /*el: 指令所绑定的元素，可以用来直接操作DOM。
		     binding: 一个对象，包含指令的很多信息。
		     vnode: Vue编译生成的虚拟节点。*/
        const path = window.location.hash.slice(1).split('?')[0]
        const { value } = binding
				// 自定义指令的执行函数，取到登录缓存的按钮权限数组
        const power = JSON.parse(sessionStorage.getItem('childrenItem'))
        const hasPermission = power.some(permission => permission.hasRole&&permission.perms === value);
      //  const haspower= power.includes(value)
       if (!hasPermission) {
         el.style.display='none'
         el.parentNode && el.parentNode.removeChild(el)
       }
       }
    })
  }
}
export default permission
